<template>
    <div id="confirmation" class="container">
        <div class="content">
            <top-bar :previous="false" @close-widget="$emit('close-widget')"></top-bar>

            <div v-if="confirmation.transaction_status >= this.orderManager.STATUS.CANCELLED">
                <div class="form-group">
                    <h1><i class="icon-payment branding-color"></i>{{ $t('confirmation.failed.title') }}</h1>
                    <p>{{ $t('confirmation.failed.subtitle') }}</p>
                </div>

                <div class="form-group">
                    <button class="button" @click="retryOrder">{{ $t('button.retry') }}</button>
                </div>

                <p><a href="#" @click.prevent="reset">{{ $t('confirmation.anotherOrder') }}</a></p>
            </div>

            <div v-if="confirmation.transaction_status === this.orderManager.STATUS.CREATED || confirmation.transaction_status === this.orderManager.STATUS.PENDING">
                <div class="form-group">
                    <h1>
                        <div class="loading branding-color"></div>
                        {{ $t('confirmation.processing.title') }}
                    </h1>
                    <p>{{ $t('confirmation.processing.subtitle') }}</p>

                    <div class="form-group">
                        <button class="button button-primary" @click="$parent.isEmbedded ? reset() : toggleWidget()">{{
                                $parent.isEmbedded ? $t('confirmation.anotherOrder') : $t('close')
                            }}
                        </button>
                    </div>

                    <p v-if="!$parent.isEmbedded"><a href="#" @click.prevent="reset">{{
                            $t('confirmation.anotherOrder')
                        }}</a></p>
                </div>
            </div>

            <div v-if="confirmation.transaction_status === this.orderManager.STATUS.APPROVAL_PENDING || confirmation.transaction_status === this.orderManager.STATUS.PAID">
                <div class="form-group" id="confirmation-animation-spacing">
                    <SentAnimationTruck v-if="confirmation.delivery"/>
                    <SentAnimation v-else/>

                    <span v-if="confirmation.transaction_status === this.orderManager.STATUS.APPROVAL_PENDING">
                        <h1>{{ $t('confirmation.pending.title') }}</h1>
                        <p>{{ $t('confirmation.pending.subtitle') }}</p>
                    </span>

                    <span v-if="confirmation.transaction_status === this.orderManager.STATUS.PAID">
                        <h1>{{ $t('confirmation.success.title') }}</h1>
                        <p>{{ $t('confirmation.success.subtitle') }}</p>
                    </span>
                </div>

                <share-button class="form-group"/>

                <div v-if="$parent.isEmbedded" class="form-group">
                    <p><a href="#" @click.prevent="reset">{{ $t('confirmation.backToStart') }}</a></p>
                </div>
                <div v-else>
                    <p><a href="#" @click.prevent="reset">{{ $t('confirmation.anotherOrder') }}</a></p>
                </div>
            </div>
        </div>

        <div class="fixed-bottom">
            <a href="https://gifty.nl" target="_blank"><img src="../../../assets/img/logo.svg" width="60" height="24"
                                                            class="logo"/></a>
        </div>
    </div>
</template>

<script>
import ShareButton from '../../elements/ShareButton.vue';
import { defineAsyncComponent } from 'vue';

const SentAnimation = defineAsyncComponent(() =>
    import('./../../elements/SentAnimation.vue')
);
const SentAnimationTruck = defineAsyncComponent(() =>
    import('./../../elements/SentAnimationTruck.vue')
);

export default {
    components: { SentAnimation, SentAnimationTruck, ShareButton },
    data() {
        return {
            // Contains the current order's status and delivery info
            // Updated during status checks and used to display UI state (failed/processing/success)
            confirmation: this.$parent.$data.confirmation,
            debug: window.Gifty.debug
        };
    },

    inject: ['orderManager'],

    methods: {
        reset() {
            this.$emit('next-view');
        },
        toggleWidget() {
            window.parent.Gifty.EventBus.$emit('widget-toggle');
        },
        retryOrder() {
            const backupOrder = this.orderManager.backupOrder;

            if (!backupOrder) {
                this.$emit('set-view', 'order', false);
                return false;
            }

            this.$parent.$data.order = backupOrder;
            this.orderManager.clearBackupOrder();
            this.$emit('set-view', 'payment', false);
            this.$emit('gen-giftcard-prev');
            return true;
        },

        /**
         * Central handler for all order status processing, regardless of source
         * This unified approach ensures consistent handling of all status changes
         * @param {Object} orderData - The order data to process
         * @param {boolean} isInitial - Whether this is the initial status check
         */
        processOrderStatus(orderData, isInitial = false) {
            // Always update our local confirmation state first
            this.confirmation = orderData;
            const status = orderData.transaction_status;

            this.debug.log('order', `Processing order status ${isInitial ? '(initial)' : '(update)'}`, {
                status,
                orderId: this.orderManager.processingOrderId
            });

            // Handle special case for initial pending status
            if (isInitial && (status === this.orderManager.STATUS.CREATED || status === this.orderManager.STATUS.PENDING) && window.parent.location.hash === '#gifty.order') {
                return this.retryOrder();
            }

            // Process the status
            if (status === this.orderManager.STATUS.CREATED || status === this.orderManager.STATUS.PENDING) {
                // Order is processing - continue verification
                this.verificationTimer = setTimeout(() => {
                    this.verifyOrderStatus(this.orderManager.processingOrderId);
                }, 3000);
                return;
            }

            // From this point on, we're handling final states that need cleanup
            if (status === this.orderManager.STATUS.APPROVAL_PENDING || status === this.orderManager.STATUS.PAID) {
                // Successful order completion
                this.debug.log('order', 'Order completed successfully', {
                    orderId: this.orderManager.processingOrderId,
                    status: status
                });

                window.parent.Gifty.EventBus.$emit('order-confirmation', this.orderManager.getBackupOrderData());

                // Clear up the order
                this.orderManager.clearOrderData();
                this.$parent.resetOrderData();

                return;
            }

            if (status === null) {
                // Invalid order
                this.confirmation.transaction_status = 404;
                this.orderManager.clearOrderData();
                return;
            }

            if (status >= this.orderManager.STATUS.CANCELLED) {
                // Failed order
                this.debug.log('order', 'Order failed', {
                    orderId: this.orderManager.processingOrderId,
                    status: status
                });

                this.orderManager.handleFailedOrder();
                window.parent.Gifty.EventBus.$emit('order-failed', orderData);
                this.orderManager.clearOrderData(true);
                return;
            }
        },


        /**
         * Verify current order status with API
         * @param {string} orderId - The order ID to verify
         */
        async verifyOrderStatus(orderId) {
            if (this.verificationTimer) {
                clearTimeout(this.verificationTimer);
            }

            try {
                const orderData = await this.orderManager.fetchOrderData(orderId);
                window.parent.Gifty.EventBus.$emit('order-status-updated', orderData);
                this.processOrderStatus(orderData);
            } catch (error) {
                this.debug.log('error', 'Order verification failed', {
                    orderId,
                    error: error.message
                });

                if (error.response?.status === 404) {
                    this.processOrderStatus({ transaction_status: null });
                }
            }
        },
    },


    mounted() {
        // Handle initial status when component mounts
        if (this.confirmation?.transaction_status !== null) {
            this.processOrderStatus(this.confirmation, true);
        }
    },

    /**
     * Clean up resources when component is destroyed
     */
    beforeDestroy() {
        if (this.verificationTimer) {
            clearTimeout(this.verificationTimer);
        }
    },
};
</script>
