<template>
    <div class="fixed-bottom">
        <slot name="subtitle" class="text-notice" v-if="subtitle" v-html="subtitle"></slot>

        <button class="button button-primary branding-button-background next-view-button" @click="handleClick"
                :class="{'button-disabled': disabled || loading, 'button-loading': loading }">
            <slot></slot>
            <i class="icon-arrow_right"></i>
        </button>
    </div>
</template>

<script>

export default {
    data() {
        return {
            errorClass: ' has-error',
        };
    },
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        onClick: {
            type: [Boolean, Function],
            default: () => false,
        },
        subtitle: {
            type: [Boolean, String],
            default: () => false,
        },
        field: {
            type: [Boolean, String],
            default: () => false,
        },

        check: {
            type: [Boolean, Function],
            default: () => false,
        },
    },
    methods: {
        /**
         * Removes the error class
         * @param {KeyboardEvent} event
         */
        removeErrorEvent(event) {
            const BANK_CARD = 'bank-card-container';
            const element = event.target;

            const removeListeners = (elems) => {
                elems.forEach((node) => {
                    const elem = node;
                    elem.removeEventListener('click', this.removeErrorEvent);
                    elem.className = elem.className.replace(this.errorClass, '');
                });
            };

            if (element.className.indexOf(BANK_CARD) !== -1) {
                const elems = this.$parent.$el.querySelectorAll(`.${BANK_CARD}`);
                removeListeners(elems);
                return;
            }

            if (element.checkValidity()) {
                element.removeEventListener('click', this.removeErrorEvent);
                element.className = element.className.replace(this.errorClass, '');

                // The view is addres if it's field contains `counter`
                // At leas one has to be selected so all errors will
                // be deleted if one is valid
                if (this.field.indexOf('counter') !== -1) {
                    const elems = this.$parent.$el.querySelectorAll(`.${this.errorClass.trim()}`);
                    removeListeners(elems);
                }
            }
        },

        /**
         * Sets the error class on the element
         * @param {HTMLInputElement} element
         */
        setErrorClass(element) {
            const editable = element;
            if (element.className.indexOf(this.errorClass) === -1) {
                editable.className += this.errorClass;
            }
            element.addEventListener('click', this.removeErrorEvent);
        },

        /**
         * Scrolls to the element and focusses
         * @param {HTMLInputElement} element
         */
        locateElement(element) {
            const container = this.$parent.$parent.$refs.scrollable;

            element.focus();
            this.$scrollTo(element, { container, offset: -50 });
        },

        /**
         * Detects and sets errors if user clicked on the button while disabled
         * If the button is not disabled it will continue and fire the `onClick` function
         * If this function is not present it will execute the `next-view` event on the parent
         * @param {MouseEvent}event
         * @returns {boolean}
         */
        handleClick(event) {
            // Sets errors
            if (this.loading || this.disabled) {
                if (this.disabled && !this.loading && this.field) {
                    this.$nextTick(() => {
                        const element = this.getEmptyElement(this.field);

                        if (element) {
                            this.locateElement(element);
                        }
                    });
                }

                if (typeof this.check === 'function') {
                    this.check();
                }

                return false;
            }

            // Run onClick function
            if (typeof this.onClick === 'function') {
                this.onClick(event);
                return true;
            }

            // Else fire the `next-view` event
            this.$parent.$emit('next-view');
            return true;
        },

        /**
         * Returns the first invalid element it can find in the field specified
         * returns false otherwise
         * @param {string}field
         * @returns {Boolean|HTMLInputElement}
         */
        getEmptyElement(field) {
            const nodes = this.$parent.$el.querySelectorAll(field);
            let found = false;
            let emptyNode;

            nodes.forEach((node) => {
                if (!node.value || !node.checkValidity()) {
                    this.setErrorClass(node);

                    if (!found) {
                        emptyNode = node;
                        found = true;
                    }
                }
            });

            return emptyNode;
        },
    },
};
</script>
