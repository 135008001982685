<template>
    <div>
        <div ref="customization">
            <transition-group name="list" tag="div" class="form-group list">
                <div key="company_name" ref="company_name" class="input-group" v-if="field_visibility.company_name">
                    <input id="company_name" ref="company_name_input" type="text" v-model="address.company_name" placeholder=" ">
                    <label for="company_name">{{ $t('label.companyName') }}</label>
                </div>

                <div key="company_contact" v-if="address_invoice" class="input-group">
                    <input id="company_contact" type="text" v-model="address.company_contact" required placeholder=" ">
                    <label for="company_contact">{{ $t('label.contactPerson') }}</label>
                </div>

                <div key="name" v-else class="input-group">
                    <input id="name" type="text" v-model="address.name" required placeholder=" ">
                    <label for="name">{{ $t('label.name') }}</label>
                </div>

                <div key="country" class="select-group">
                    <select id="country" v-model="address.country" @change="selectedCountryChanged">
                        <option v-for="(country, code) in $parent.settings.delivery.options" :value="code">
                            {{ $t('country.' + code) }}
                        </option>
                    </select>
                    <label for="country">{{ $t('label.country') }}</label>
                </div>

                <div key="zip" class="input-group">
                    <input id="zip" class="zip" type="text" v-model="address.zip" pattern="^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$" required placeholder=" ">
                    <label for="zip">{{ $t('label.zip') }}</label>
                </div>

                <div key="street" class="input-group" v-if="showAllInputs">
                    <input id="street" type="text" v-model="address.street" required placeholder=" ">
                    <label for="street">{{ $t('label.street') }}</label>
                </div>

                <div key="house_number" :class="[ 'input-group', { 'input-loading': showSearchIcon } ]">
                    <input id="house_number" type="text" v-model="address.house_number" required placeholder=" "
                           maxlength="10">
                    <label for="house_number">{{ $t('label.nrAddition') }}</label>
                </div>

                <div key="city" class="input-group" v-if="showAllInputs">
                    <input id="city" type="text" v-model="address.city" required placeholder=" ">
                    <label for="city">{{ $t('label.city') }}</label>
                </div>

                <div key="addition" ref="addition" class="input-group" v-if="field_visibility.addition">
                    <input id="addition" ref="addition_input" type="text" v-model="address.addition" required
                           placeholder=" ">
                    <label for="addition">{{ $t('label.extraAddressLine') }}</label>
                </div>

                <div key="houseNumberHasStreetSimilarity" class="form-group" v-if="houseNumberHasStreetSimilarity">
                    <div class="alert danger-alert">
                        {{ $t('errors.houseNumberHasStreetSimilarity') }}
                    </div>
                </div>
            </transition-group>
        </div>

        <div ref="addressFound" class="form-group" v-if="addressFound || !usePostcodeApi">
            <div class="input-group address-group">
                <h3 v-if="!showAllInputs">{{ $t('foundAddress') }}</h3>
                <p v-if="!showAllInputs">
                    {{address.street}} {{address.house_number}}, {{address.city}}
                    <small v-if="!showAllInputs">
                        <button class="button-link customize-address" @click="showField('customization')">
                            {{ $t('edit') }}
                        </button>
                    </small>
                </p>
                <p>
                    <small>
                        <span v-if="!field_visibility.company_name">
                            <button class="button-link"
                                    @click="showField('company_name')">{{ $t('button.addCompanyName') }}</button>
                            <span v-if="!field_visibility.addition">|</span>
                        </span>
                        <button v-if="!field_visibility.addition" class="button-link"
                                @click="showField('addition')">
                            {{ $t('button.addAddressLine') }}
                        </button>
                    </small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            address_invoice: false,
            searching: false,
            field_visibility: {
                company_name: false,
                addition: false,
                customization: false, // Visablity of the default filled fields
            },
            houseNumberHasStreetSimilarity: false,
            countriesWithPostcodeAPI: ['NL'],
        };
    },
    computed: {
        addressFound() {
            return this.address.street && this.address.house_number;
        },
        showSearchIcon() {
            return this.address.house_number.length && this.searching;
        },
        showAllInputs() {
            return this.field_visibility.customization || !this.usePostcodeApi;
        },
        usePostcodeApi() {
            return this.countriesWithPostcodeAPI.includes(this.address.country);
        },
    },
    watch: {
        'address.house_number': {
            handler() {
                const streetPrefix = this.address.street.substr(0, 4).toLowerCase();
                const houseNumPrefix = this.address.house_number.substr(0, 4).toLocaleLowerCase();

                this.houseNumberHasStreetSimilarity = streetPrefix === houseNumPrefix;
            },
            deep: true,
        },

        'address.zip': 'setAddress',
        addressFound: function addressFound(zip) {
            if (!zip) return;

            this.$nextTick(() => {
                this.$scrollTo(this.$refs.addressFound, 500, {
                    container: this.$parent.$parent.$refs.scrollable,
                });
            });
        },
    },
    methods: {
        selectedCountryChanged(to) {
            this.$emit('countryUpdated', to);
        },
        setAddress(zip) {
            let zipRegex = '';
            // Connect to Postcode API for each country
            switch (this.address.country) {
                case 'NL':
                    // Regex matching Dutch postal codes
                    zipRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

                    // Run if valid postal code and manual input is not enabled
                    if (zipRegex.test(zip) && !this.searching) {
                        this.searching = true;

                        window.Gifty.axios.get(`${import.meta.env.VITE_API_URL}/address/${zip}`, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }).then(({ data }) => {
                            this.searching = false;

                            if (data.status === 'ok') {
                                const address = data.results[0];

                                this.address.street = address.street;
                                this.address.city = address.city;
                            } else {
                                this.showField('customization');
                            }
                        }).catch(() => {
                            this.searching = false;

                            this.showField('customization');
                        });
                    }
                    break;
                default:
                    break;
            }
        },
        showField(field) {
            this.field_visibility[field] = true;
            const inputField = `${field}_input`;

            this.$nextTick(() => {
                this.$scrollTo(this.$refs[field], 500, {
                    container: this.$parent.$parent.$refs.scrollable,
                });

                if (typeof (this.$refs[inputField]) !== 'undefined' && this.$refs[inputField] != null) {
                    this.$refs[inputField].focus();
                }
            });
        },
    },
    props: {
        address: Object,
    },
    mounted() {
        if (typeof this.address.company_contact !== 'undefined') {
            this.address_invoice = true;
            this.field_visibility.company_name = true;
        }
    },
};
</script>
