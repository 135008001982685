<template>
    <div id="terms_conditions" class="container" ref="terms_conditions">
        <div class="content">
            <top-bar @prev-view="$emit('set-view', 'payment')"
                     @close-widget="$emit('set-view', 'payment')"></top-bar>

            <h1>{{ $t('terms.title') }}</h1>
            <p>{{ $t('terms.description') }}</p>
            <ul>
                <li>
                    <a :href="termsUrl" target="_blank" rel="noreferrer">
                        {{ $t('terms.terms_conditions') }}
                    </a>
                </li>

                <li v-if="typeof $parent.settings.additional_terms !== 'undefined'">
                    <a :href="$parent.settings.additional_terms" target="_blank" rel="noreferrer">
                        {{ $t('terms.additional_terms') }}
                    </a>
                </li>

                <li>
                    <a :href="privacyUrl" target="_blank" rel="noreferrer">
                        {{ $t('terms.privacy_statement') }}
                    </a>
                </li>

                <li v-if="typeof $parent.settings.additional_privacy_statement !== 'undefined'">
                    <a :href="$parent.settings.additional_privacy_statement" target="_blank" rel="noreferrer">
                        {{ $t('terms.additional_privacy_statement') }}
                    </a>
                </li>
            </ul>
        </div>

        <div class="fixed-bottom">
            <button class="button button-primary branding-button-background" @click="accept">
                {{ $t('payment.accept') }}
                <i class="icon-arrow_right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            order: this.$parent.$data.order,
        };
    },

    computed: {
        termsUrl() {
            // Check if current locale is Dutch
            if (this.$i18n.locale === 'nl-NL' || this.$i18n.locale === 'nl-BE') {
                return 'https://gifty.nl/buyer-terms-and-conditions-nl.pdf';
            }
            return 'https://gifty.nl/buyer-terms-and-conditions-en.pdf';
        },

        privacyUrl() {
            // Check if current locale is Dutch
            if (this.$i18n.locale === 'nl-NL' || this.$i18n.locale === 'nl-BE') {
                return 'https://gifty.nl/privacy-statement-nl.pdf';
            }
            return 'https://gifty.nl/privacy-statement-en.pdf';
        }
    },

    methods: {
        accept() {
            this.order.terms_conditions = true;
            this.$emit('set-view', 'payment', false, 'terms_conditions');
        },
    },

    mounted() {
        if (this.$parent.$data.view_ref) {
            this.$nextTick(() => {
                this.$parent.$refs.scrollable.scrollTop = this.$refs[this.$parent.$data.view_ref]
                    .offsetTop;

                this.$parent.$data.view_ref = false;
            });
        } else {
            this.$parent.$refs.scrollable.scrollTop = 0;
        }
    },
};
</script>
