export class ActionCodes {
    constructor() {
        this.API_URL = import.meta.env.VITE_API_URL;
    }

    /**
     * Validates an action code against the API
     * @param {Object} params - Validation parameters
     * @param {string} params.actionCode - The action code to validate
     * @param {string} params.key - Widget key
     * @param {number} params.orderValue - Current order total value
     * @returns {Promise} - Resolves with action code data or rejects with error
     */
    async validateActionCode({ actionCode, key, orderValue }) {
        try {
            const response = await window.Gifty.axios.post(`${this.API_URL}/validate_action`, {
                action_code: actionCode,
                key: key,
                order_value: orderValue
            });

            return response.data.data;
        } catch (error) {
            // Preserve the original error structure for consistent error handling
            throw error;
        }
    }

    /**
     * Checks if a gift card qualifies for the action code criteria
     * @param {Object} giftCard - The giftCard to check
     * @param {Array} criteria - Action code criteria to check against
     * @returns {boolean} - Whether the giftCard qualifies
     */
    isGiftCardQualifying(giftCard, criteria) {
        return criteria.every(criterion => {
            switch (criterion.type) {
                case 'min_amount':
                    const minimumAmount = parseInt(criterion.config.amount);
                    return giftCard.value >= minimumAmount;

                case 'package':
                    return criterion.config.package_ids.includes(giftCard.package_id);

                default:
                    return false;
            }
        });
    }

    /**
     * Gets all qualifying giftcards for given criteria
     * @param {Array} giftCards - List of giftcards to check
     * @param {Array} criteria - Action code criteria
     * @returns {Array} - List of qualifying giftcards
     */
    getQualifyingGiftCards(giftCards, criteria) {
        return giftCards.reduce((qualifying, giftCard) => {
            if (this.isGiftCardQualifying(giftCard, criteria)) {
                // Add based on the quantity
                for (let i = 0; i < giftCard.quantity; i++) {
                    qualifying.push({
                        ...giftCard,
                        originalQuantity: giftCard.quantity
                    });
                }
            }
            return qualifying;
        }, []);
    }
}

export default ActionCodes;
