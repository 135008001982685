const createDebugTools = (Gifty) => {
    const instanceId = 'Gifty_' + Math.random()
        .toString(36)
        .substr(2, 9);

    const styles = {
        base: 'border-radius: 3px; padding: 2px 4px; font-weight: bold;',
        instance: 'background: #3498db; color: white;',
        event: 'background: #2ecc71; color: white;',
        gtm: 'background: #e74c3c; color: white;',
        fbq: 'background: #9b59b6; color: white;',
        info: 'background: #4E7CBF; color: white;',
        warning: 'background: #f39c12; color: white;',
        error: 'background: #c0392b; color: white;',
        debug: 'background: #34495e; color: white;'
    };

    const formatArgs = (args) => {
        return args.map(arg => {
            if (typeof arg === 'object' && arg !== null) {
                return JSON.stringify(arg, null, 2);
            }
            return arg;
        });
    };

    const debug = {
        isEnabled: false,
        instanceId: instanceId,
        enable: function () {
            this.isEnabled = true;
            localStorage.setItem('giftyDebug', 'true');
            this.log('debug', 'Debugging enabled');
        },
        disable: function () {
            this.isEnabled = false;
            localStorage.removeItem('giftyDebug');
            this.log('debug', 'Debugging disabled');
        },
        log: function (type, message, ...args) {
            if (!this.isEnabled) return;

            const typeStyle = styles[type] || styles.base;
            const instanceStyle = styles.instance;
            const formattedArgs = formatArgs(args);

            const logMessage = `%c${this.instanceId}%c ${type.toUpperCase()} %c ${message}`;
            const logStyles = [
                instanceStyle + styles.base,
                typeStyle + styles.base,
                'color: inherit; font-weight: normal;'
            ];

            if (formattedArgs.length > 0) {
                console.groupCollapsed(logMessage, ...logStyles);
                console.log(...formattedArgs);
                console.groupEnd();
            } else {
                console.log(logMessage, ...logStyles);
            }
        },
        events: new Map(),
        getEventHistory: function () {
            return Array.from(this.events.entries());
        },

        setupTestConfirmation: async function (plugins) {
            const urlParams = new URLSearchParams(window.location.search);
            const testConfirmation = urlParams.get('giftyTestConfirmation');

            if (testConfirmation === null) {
                return;
            }

            // Clear previous tracking data for all plugins
            plugins.forEach(plugin => {
                if (typeof plugin.clearPreviousTracking === 'function') {
                    plugin.clearPreviousTracking();
                }
            });

            this.log('debug', 'Test confirmation parameter detected', { testConfirmation });

            // Prepare test order data
            const testOrderData = {
                'order_id': '119649',
                'giftcards': [{
                    'value': 2533,
                    'quantity': 1,
                    'title': null,
                    'package_id': null,
                    'message': [null, '']
                }],
                'phone': '0612345678',
                'personalize': 'no',
                'shipment': 'email',
                'delivery_method': 'standard',
                'wrapping': false,
                'payment_method': null,
                'newsletter': false,
                'address': {
                    'country': 'NL',
                    'company_name': '',
                    'name': '',
                    'email': 'dev@gifty.nl',
                    'street': '',
                    'house_number': '',
                    'zip': '',
                    'city': '',
                    'addition': ''
                },
                'address_invoice': {
                    'country': 'NL',
                    'company_name': '',
                    'company_contact': '',
                    'street': '',
                    'house_number': '',
                    'zip': '',
                    'city': '',
                    'addition': ''
                }
            };

            try {
                await sessionStorage.setItem('gifty.order', JSON.stringify(testOrderData));
                this.log('debug', 'Test order data set in sessionStorage', { orderId: testOrderData.order_id });
            } catch (error) {
                this.log('error', 'Failed to set test order data in sessionStorage', { error: error.message });
            }

            try {
                await localStorage.setItem('gifty.activeOrder', testConfirmation);
                this.log('debug', 'Test confirmation order ID set in localStorage', { testConfirmation });
            } catch (error) {
                this.log('error', 'Failed to set test confirmation order ID in localStorage', { error: error.message });
            }

            this.log('debug', 'Finished initializing test confirmation');
            return true;
        },
    };

    // Check if debug was previously enabled
    if (localStorage.getItem('giftyDebug') === 'true') {
        debug.enable();
    }

    return debug;
};

export default createDebugTools;
