const loadLocaleMessages = async (locale) => {
    let messages;

    switch (locale) {
        case 'nl':
        case 'nl-NL':
            messages = await import('./nl-NL.json');
            break;

        case 'nl-BE':
            messages = await import('./nl-BE.json');
            break;

        case 'fr-BE':
            messages = await import('./fr-BE.json');
            break;

        case 'fr':
        case 'fr-FR':
            messages = await import('./fr-FR.json');
            break;

        case 'de':
        case 'de-DE':
            messages = await import('./de-DE.json');
            break;

        case 'en-US':
            messages = await import('./en-US.json');
            break;

        default:
            // Fallback to English
            messages = await import('./en-GB.json');
    }

    return messages.default;
}

export { loadLocaleMessages };
