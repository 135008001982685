<template>
    <div id="frame" :class="{embedded: isEmbedded, mobile: isMobile}">
        <div id="widget">
            <div class="slider" :class="{'full-height': (view === 'confirmation') || (intro === true)}"
                 ref="scrollable">
                <transition name="slide" mode="out-in">
                    <payment-redirect key="payment_redirect" v-if="payment_secure === false"
                                      :shopUrl="settings.shop_url" :name="settings.name"></payment-redirect>
                    <payment-warning key="payment_warning" v-else-if="settings.payment_status === false"
                                     :name="settings.name"></payment-warning>
                    <intro key="intro" v-else-if="intro" @close-widget="closeWidget" @close-intro="intro = false"
                           ref="intro"></intro>
                    <component :key="`view_${view}`" v-else v-bind:is="view" @prev-view="prevView" @next-view="nextView"
                               ref="component" :scrollTo="this.view_ref" @set-scroll-to="(ref) => this.view_ref = ref"
                               @set-view="setView" @gen-giftcard-prev="getGiftcardPreviewsCheck"/>
                </transition>
            </div>

            <div id="breadcrumb" v-if="intro !== true && view !== 'confirmation'">
                <ol>
                    <li @click="setView('order', true)"
                        :class="{active: view === 'order', finished: view_state.order === true}">
                        <div><i class="icon-giftcard" :class="{'branding-color': view === 'order'}"></i>{{
                                $t('order')
                            }}
                        </div>
                    </li>
                    <li @click="setView('personalize', true)"
                        :class="{active: view === 'personalize', finished: view_state.personalize === true}">
                        <div><i class="icon-chat-group" :class="{'branding-color': view === 'personalize'}"></i>{{
                                $t('message')
                            }}
                        </div>
                    </li>
                    <li @click="setView('shipment', true)"
                        :class="{active: view === 'shipment', finished: view_state.shipment === true}">
                        <div><i class="icon-envelope" :class="{'branding-color': view === 'shipment'}"></i>{{
                                $t('dispatch')
                            }}
                        </div>
                    </li>
                    <li @click="setView('payment', true)"
                        :class="{active: view === 'payment', finished: view_state.payment === true}">
                        <div><i class="icon-payment" :class="{'branding-color': view === 'payment'}"></i>{{
                                $t('checkOut')
                            }}
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import Intro from './screens/Intro/Intro.vue';
import Order from './screens/Order/Order.vue';
import PaymentRedirect from './screens/Payment/PaymentRedirect.vue';
import Personalize from './screens/Personalize.vue';
import Shipment from './screens/Shipment/Shipment.vue';
import Payment from './screens/Payment/Payment.vue';
import Confirmation from './screens/Payment/Confirmation.vue';
import TermsConditions from './screens/Payment/TermsConditions.vue';
import PaymentWarning from './screens/Payment/PaymentWarning.vue';
import Vue from 'vue';
import { DeliveryEstimates } from '../utils/DeliveryEstimates.js';
import ActionCodes from '../utils/ActionCodes.js';

export default {
    data() {
        return {
            confirmation: {},
            order: {
                order_id: null,
                return_url: top.window.location.href,
                key: window.parent.Gifty.key,
                giftcards: [],
                phone: '',
                personalize: '',
                shipment: false,
                delivery_method: 'standard',
                delivery_selected: null,
                coupon: '',
                coupon_value: 0,
                action_code: '',
                action_code_data: {},
                wrapping: false,
                invoice: false,
                payment_method: null,
                payment_bank: false,
                account_owner: '',
                account_country: '',
                all_banks_visible: false,
                terms_conditions: false,
                newsletter: false,
                address: {
                    country: '',
                    company_name: '',
                    name: '',
                    email: '',
                    street: '',
                    house_number: '',
                    zip: '',
                    city: '',
                    addition: '',
                },
                address_invoice: {
                    country: '',
                    company_name: '',
                    company_contact: '',
                    street: '',
                    house_number: '',
                    zip: '',
                    city: '',
                    addition: '',
                },
            },
            view: 'order',
            view_ref: false,
            views: ['confirmation', 'order', 'personalize', 'shipment', 'payment', 'terms_conditions'],
            view_state: {
                order: false,
                personalize: false,
                shipment: false,
                payment: false,
            },
            payment_secure: true,
            intro: typeof window.parent.Gifty.tutorial === 'undefined' || window.parent.Gifty.tutorial,
            isMobile: false,
            warning: false,
            giftcardExamples: [],
            giftcardExamplesThumbs: [],
            giftcardExamplesStatus: 'first',
        };
    },

    props: {
        settings: {},
        isEmbedded: {
            default: false,
            type: Boolean,
        },
    },

    components: {
        PaymentRedirect: PaymentRedirect,
        intro: Intro,
        order: Order,
        personalize: Personalize,
        shipment: Shipment,
        payment: Payment,
        confirmation: Confirmation,
        terms_conditions: TermsConditions,
        paymentWarning: PaymentWarning,
    },

    methods: {
        closeWidget() {
            window.parent.Gifty.EventBus.$emit('widget-close');
        },
        nextView() {
            this.setView(1);
        },
        prevView() {
            if (this.view === 'order') {
                this.intro = true;
            } else {
                this.setView(-1);
            }
        },

        /**
         * Add a gift card to the order.giftcards array if it doesn't exist yet
         * If it does exist, increase the quantity
         * @param type Type of gift card (gift-card, gift-card-package)
         * @param value Value of the gift card (2500, 5000, 10000 or gp_1, gp_2, gp_3)
         * @param quantity Quantity of gift cards to add
         * @returns {boolean}
         */
        addGiftcard(type, value, quantity = 1) {
            // Validate if the type is either gift-card or gift-card-package
            if (type !== 'gift-card' && type !== 'gift-card-package') {
                console.warn('Invalid gift card type, must be gift-card or gift-card-package')
                return false;
            }

            // Validate the value is a positive integer for the gift-card type
            if (type === 'gift-card' && !Number.isInteger(value)) {
                console.warn('Invalid gift card value, must be a positive integer')
                return false;
            }

            // Validate the value is a string for the gift-card-package type
            if (type === 'gift-card-package' && typeof value !== 'string') {
                console.warn('Invalid gift card value, must be a string')
                return false;
            }

            // Validate the quantity is a positive integer
            if (!Number.isInteger(quantity) || quantity < 1) {
                console.warn('Invalid gift card quantity, must be a positive integer')
                return false;
            }

            let cardPackage = false;

            if(type === 'gift-card-package') {
                cardPackage = this.settings.giftcard_packages.find(gp => gp.id === value);
                value = cardPackage.amount;

                if(typeof cardPackage === 'undefined') {
                    console.warn('Invalid gift card package, must be a valid package id')
                    return false;
                }
            }

            const giftCard = this.order.giftcards.find(giftcard => giftcard.value === value && giftcard.package_id === (cardPackage.id ?? null));

            if (typeof giftCard !== 'undefined') {
                giftCard.quantity += quantity;
                return true;
            }

            this.order.giftcards.push({
                value,
                quantity: quantity,
                title: cardPackage.title ?? null,
                package_id: cardPackage.id ?? null,
                message: [],
            });

            return true;
        },

        /**
         * Remove a gift card from the order.giftcards array
         * @param value
         * @param packageId
         * @returns {boolean}
         */
        deleteGiftcard(value, packageId = false) {
            const giftcard = this.order.giftcards.find(giftcard => giftcard.value === value && giftcard.package_id === (packageId ?? null));

            if (typeof giftcard === 'undefined') {
                return false;
            }

            if (giftcard.quantity > 1) {
                giftcard.quantity -= 1;
                return true;
            }

            const index = this.order.giftcards.indexOf(giftcard);
            this.order.giftcards.splice(index, 1);

            return true;
        },

        /**
         * Sets the view of the widget
         * @param {String|Number} newView String of the view or relative integer
         * @param {boolean} userInput True if the event is called by a user(prevents skipping steps)
         * @param {String|boolean|function} scrollToRef Name of the ref
         */
        setView(newView, userInput = false, scrollToRef = false) {
            let view = newView;
            const currentViewIndex = this.views.indexOf(this.view);

            if (typeof newView === 'number') {
                view = this.views[currentViewIndex + newView];
            }

            const newViewIndex = this.views.indexOf(view);

            // Only allow navigating backwards from breadcrumb
            if (newViewIndex >= currentViewIndex && userInput) {
                return;
            }

            for (let i = 0; i < this.views.length; i += 1) {
                this.view_state[this.views[i]] = newViewIndex > i;
            }

            if (typeof scrollToRef === 'string') {
                this.view_ref = scrollToRef;
            }

            this.view = view;

            if (typeof scrollToRef === 'function') {
                this.$nextTick(() => {
                    scrollToRef(this.$refs);
                });
            }
        },
        setMobile() {
            this.isMobile = !window.parent.matchMedia('(min-width: 576px)').matches;
        },
        shippingCosts(shipment, deliveryMethod = 'standard') {
            let costs = 0;
            let costsPerGiftCard = 0;
            const freeDeliveryAbove = this.settings.delivery.discount_threshold;
            const country = typeof this.order.address.country !== 'undefined' ? this.order.address.country
                                                                              : 'NL';
            const shipmentOptions = this.settings.delivery.options[country];
            const shipmentOption = shipmentOptions.find(option => option.method === deliveryMethod);

            if (shipment === 'post') {
                costsPerGiftCard = 0;
                costsPerGiftCard = typeof shipmentOption !== 'undefined' ? shipmentOption.delivery_costs : costsPerGiftCard;
            }

            this.order.giftcards.forEach((giftCard) => {
                // If the shipping costs shouldn't be multiplied by item count,
                // skip calculation when costs are applied already
                if(
                    costs !== 0 &&
                    shipmentOption !== 'undefined' &&
                    shipmentOption.multiply_costs_by_items === 0
                ) {
                    return;
                }

                // If costs are not applied yet but costs shouldn't be multiplied,
                // we set the item quantity to 1 for the calculation
                let quantity = giftCard.quantity;
                if(
                    shipmentOption !== 'undefined' &&
                    shipmentOption.multiply_costs_by_items === 0
                ) {
                    quantity = 1;
                }

                if (giftCard.value < freeDeliveryAbove || freeDeliveryAbove === null) {
                    costs += (costsPerGiftCard * quantity);
                } else if (deliveryMethod !== 'standard') {
                    const optionCountry = this.settings.delivery.options[country];

                    // Retrieve selected method delivery price
                    const methodDeliveryOption = optionCountry
                        .find(deliveryOpt => deliveryOpt.method === deliveryMethod);

                    const methodDeliveryPrice =
                        typeof methodDeliveryOption !== 'undefined' ? methodDeliveryOption.delivery_costs : costsPerGiftCard;

                    // Retrieve standard delivery price
                    const standardDeliveryOption = optionCountry
                        .find(deliveryOpt => deliveryOpt.method === 'standard');

                    const standardDeliveryPrice =
                        typeof standardDeliveryOption !== 'undefined' ? standardDeliveryOption.delivery_costs : costsPerGiftCard;

                    // Calculate total costs
                    const totalCost = methodDeliveryPrice - standardDeliveryPrice;
                    costs += (totalCost * quantity);
                }
            });

            return Math.round(costs);
        },
        initDeliveryEstimateUtil() {
            // Make it available globally in Vue instance
            Vue.prototype.$deliveryEstimate = new DeliveryEstimates(this.settings.delivery);
        },
        checkOpenOrder(orderId) {
            window.Gifty.axios.get(`${import.meta.env.VITE_API_URL}/order/${orderId}`)
                .then(({ data }) => {
                    this.order.order_id = orderId;
                    this.confirmation = data;
                    this.intro = false;
                    this.setView('confirmation');
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 404) {
                            // Invalid order, remove
                        }
                    }
                })
                .finally(() => {
                    localStorage.removeItem('gifty.activeOrder');
                });
        },
        getGiftcardPreviewsCheck() {
            const that = this;
            if (this.giftcardExamplesStatus === 'first') {
                // Get thumbs
                this.getGiftcardPreviews(true)
                    .then((data) => {
                        that.giftcardExamplesStatus = 'done_first';
                        that.giftcardExamplesThumbs = data;
                    });
            } else if (this.giftcardExamplesStatus === 'changed') {
                that.giftcardExamples = [];
                // Get thumbs
                this.getGiftcardPreviews(true)
                    .then((data) => {
                        that.giftcardExamplesStatus = 'done_changed';
                        that.giftcardExamplesThumbs = data;
                    });
            }
        },
        getGiftcardPreviews(thumbs = false) {
            const giftcards = JSON.parse(JSON.stringify(this.order.giftcards));
            if (this.order.personalize === 'unique') {
                giftcards.forEach((giftcard) => {
                    giftcard.message.splice(0, 1);
                });
            } else if (this.order.personalize === 'yes') {
                giftcards.forEach((giftcard, index) => {
                    for (let i = 0; i < giftcard.quantity; i += 1) {
                        giftcards[index].message[i] = this.order.message;
                    }
                });
            } else {
                giftcards.forEach((giftcard, index) => {
                    giftcards[index].message = [];
                });
            }
            let thumbStatus = '';
            if (thumbs) {
                thumbStatus = '/thumbs';
            }
            return new Promise((resolve, reject) => {
                window.Gifty.axios.post(`${import.meta.env.VITE_API_URL}/company/${this.settings.company_id}/giftcard/multiple-previews${thumbStatus}`, {
                    giftcards,
                })
                    .then((data) => {
                        resolve(data.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getGiftcardBigPreviews() {
            const that = this;
            this.getGiftcardPreviews()
                .then((data) => {
                    that.giftcardExamples = data;
                    that.giftcardExamplesStatus = 'done';
                });
        },

        /**
         * Handles programmatic action code application
         * @param {string} code - The action code to apply
         */
        async setActionCode(code) {
            // Remove the intro
            this.intro = false;

            const actionCodes = new ActionCodes();

            try {
                const actionCodeData = await actionCodes.validateActionCode({
                    actionCode: code,
                    key: this.order.key,
                    orderValue: this.giftcardsTotal
                });

                this.order.action_code = code;
                this.order.action_code_data = actionCodeData;
                window.Gifty.debug.log('info', 'Action code applied successfully', { code });
            } catch (error) {
                this.order.action_code = '';
                this.order.action_code_data = null;

                window.Gifty.debug.log('error', 'Action code validation failed', {
                    code,
                    error: error.response?.data?.errors || error.message
                });

                throw error;
            }
        },

        /**
         * Check URL parameters for action code and apply if found
         */
        checkUrlActionCode() {
            const urlParams = new URLSearchParams(window.parent.location.search);
            const actionCode = urlParams.get('action_code');

            if (actionCode) {
                window.Gifty.debug.log('info', 'Action code found in URL', { actionCode });
                this.setActionCode(actionCode).catch(error => {
                    window.Gifty.debug.log('error', 'Failed to apply URL action code', {
                        actionCode,
                        error: error.response?.data?.errors || error.message
                    });
                });
            }
        },
    },

    computed: {
        totalGiftcards() {
            let count = 0;

            this.order.giftcards.forEach((giftCard) => {
                count += giftCard.quantity;
            });

            return count;
        },
        giftcardsTotal() {
            let total = 0;

            // Sum of giftcard value
            this.order.giftcards.forEach((order) => {
                total += (order.value * order.quantity);
            });

            return total;
        },
        giftcardsWithoutFreeShipping() {
            const freeDeliveryAbove = this.settings.delivery.discount_threshold;
            let count = 0;

            this.order.giftcards.forEach((giftcard) => {
                if (giftcard.value < freeDeliveryAbove || freeDeliveryAbove === null) {
                    count += giftcard.quantity;
                }
            });

            return count;
        },
    },

    watch: {
        'order.giftcards': {
            handler() {
                if (this.giftcardExamples.length > 0) {
                    this.giftcardExamplesStatus = 'changed';
                }
            },
            deep: true,
        },
        'order.personalize': {
            handler() {
                if (this.giftcardExamples.length > 0) {
                    this.giftcardExamplesStatus = 'changed';
                }
            },
            deep: true,
        },
        'order.message': {
            handler() {
                if (this.giftcardExamples.length > 0) {
                    this.giftcardExamplesStatus = 'changed';
                }
            },
            deep: true,
        },
    },

    beforeMount() {
        this.initDeliveryEstimateUtil();
    },

    mounted() {
        window.Gifty.EventBus.$on('internal-add-to-cart', (product) => {
            // Hide intro tutorial
            this.intro = false;

            const success = this.addGiftcard(product.type, product.value, product.quantity);

            if(success) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.setView('order', false, 'cart-items');
                    }, 50);
                });
            }
        });

        this.checkUrlActionCode();
        window.Gifty.EventBus.$on('apply-action-code', (code) => {
            this.setActionCode(code);
        });

        window.Gifty.EventBus.$on('set-test-data', (data) => {
            console.log('Updating test data')

            // Overwrite passed props
            this.order = {
                ...this.order,
                ...data,
            }
        });


        window.Gifty.EventBus.$on('log-test-data', () => {
            console.log('Logging test data')
            console.log(JSON.stringify(this.order))
        });

        if (window.parent.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
            this.payment_secure = false;
        }

        const orderId = this.settings.order_id;
        const country = navigator.languages.join()
            .toLowerCase()
            .indexOf('be') !== -1 ? 'BE' : 'NL';

        this.order.address.country = country;
        this.order.address_invoice.country = country;

        if (orderId) {
            this.checkOpenOrder(orderId);
        }

        this.setMobile();
        window.parent.addEventListener('resize', this.setMobile);
    },

    beforeDestroy() {
        window.parent.removeEventListener('resize', this.setMobile);
        window.Gifty.EventBus.$off('apply-action-code', this.setActionCode);
    },
};
</script>
