<script>
import Vue from 'vue';
import { i18n } from '../main';
import toggleCss from '../assets/style/toggle.scss?inline';
import appCss from '../assets/style/app.scss?inline';
import toastCss from '../assets/style/toast.scss?inline';

export default {
    render(h) {
        return h('iframe', {
            on: { load: this.renderChildren },
        });
    },
    props: [
        'cssFile',
    ],
    methods: {
        renderChildren() {
            const frame = this;

            const css = {
                toggle: toggleCss,
                app: appCss,
                toast: toastCss
            };

            Vue.nextTick(() => {
                const children = frame.$slots.default;
                const head = frame.$el.contentDocument.head;
                const body = frame.$el.contentDocument.body;
                const el = document.createElement('div');

                const metaViewport = document.createElement('meta');
                metaViewport.name = 'viewport';
                metaViewport.content = 'width=device-width, initial-scale=1';
                head.appendChild(metaViewport);

                const metaEncoding = document.createElement('meta');
                metaEncoding.name = 'charset';
                metaEncoding.content = 'utf-8';
                head.appendChild(metaEncoding);

                /**
                 * We run our payment integration in a fully separate file. This is because
                 * of the way we render our widget within different iframes. Stripe is unable
                 * to work with this out of the box, because our JavaScript is executed in a
                 * different frame then the frame the Stripe element is rendered in.
                 *
                 * By full separation of the initialization logic, we are able to execute
                 * this logic in the same frame as where the Stripe element is rendered.
                 */
                if (this.cssFile === 'app') {
                    const paymentJS = document.createElement('script');
                    paymentJS.type = 'module';
                    paymentJS.src = import.meta.env.PROD ? `${import.meta.env.BASE_URL}/js/payment.js` :  new URL('../payment.js', import.meta.url).href;
                    head.appendChild(paymentJS);
                }

                const style = document.createElement('style');
                let branding = '';

                if (this.cssFile === 'app') {
                    branding = `
                        .branding-color,
                        .form-group .checkbox-group input[type="checkbox"]:not(:checked) + label:after, .form-group .checkbox-group input[type="checkbox"]:checked + label:after, .card-selected .card-button:after {
                            color: ${this.$root.settings.primary_color} !important;
                        }
                        .branding-background {
                            background-color: ${this.$root.settings.primary_color} !important;
                        }
                        .branding-border {
                            border-color: ${this.$root.settings.primary_color} !important;
                        }
                        .card-selected, .card-selected:before {
                            border-color: ${this.$root.settings.primary_color} !important;
                        }
                        .loading.branding-color {
                            border-top-color: ${this.$root.settings.primary_color} !important;
                        }
                        .confirmation-animation *:not(#background) {
                            fill: ${this.$root.settings.primary_color};
                            stroke: ${this.$root.settings.primary_color};
                        }
                        .confirmation-animation-truck #road,
                        .confirmation-animation-truck #truck,
                        .confirmation-animation-truck #sign,
                        .confirmation-animation-truck #cloud,
                        .confirmation-animation-truck #cloud path {
                            fill: ${this.$root.settings.primary_color};
                            stroke: ${this.$root.settings.primary_color};
                        }`;

                    if(this.$root.settings.button_color) {
                        branding += `
                        .branding-button-background {
                            background-color: ${this.$root.settings.button_color} !important;
                        }
                        `;
                    }

                    if(this.$root.settings.success_color) {
                        branding += `
                        .branding-text-success {
                            color: ${this.$root.settings.success_color} !important;
                        }
                        `;
                    }
                }

                style.innerHTML = '@charset "UTF-8";\n' + css[this.cssFile].toString() + branding;

                // Base path
                const base = document.createElement('base');
                base.href = import.meta.env.BASE_URL;

                head.append(base);
                head.appendChild(style);
                body.appendChild(el);

                new Vue({
                    el,
                    i18n,
                    name: 'giftyApp',
                    data: {
                        children: Object.freeze(children),
                        sandboxReference: frame.$el.contentWindow,
                    },
                    render(h) {
                        return h('div', this.children);
                    },
                });
            });
        },
    },
};
</script>
